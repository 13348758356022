const NoticeBoardCard = ({ data }) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
      <div className="how-left">
        <h2>Notice Board</h2>
        <hr />
        <>
          <p>
            BEST&nbsp;<strong>{data.title}</strong>
            &nbsp; {data.hashTag}
          </p>
          <p>{data.deposite1}</p>
          <p>{data.withdrawal1}</p>
          <p>{data.withdrawal2}</p>
        </>
      </div>
    </div>
  );
};

export default NoticeBoardCard;
