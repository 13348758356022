import React from 'react';
import PlaystoreAppStoreCard from './PlaystoreAppstoreCard';
import GooglePlayLogo from "../images/googleplay.png";

const MatakGamesRate = ({ data }) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div className="how-right">
        {/* <h2>Matka Game Rates </h2> */}
        <h2>Mobile Apps</h2>
        <ul>
        <h4>We are coming with our mobile apps soon. Stay connected...</h4>
        <img
              src={GooglePlayLogo}
              alt="Online Matka Play App"
              loading="lazy"
              style={{ height: 250 }}
            />
        {/* <PlaystoreAppStoreCard /> */}
          {/* {data.map((item, index) => {
            return (
              <li key={`${item.name}-${index}`}>
                <p>
                  <i className="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                  {item.name} <span>{item.rate}</span>
                </p>
              </li>
            );
          })} */}
        </ul>
      </div>
    </div>
  );
};

export default MatakGamesRate;