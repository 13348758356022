import React, { useCallback, useState } from "react";
import {
  getCurrentDayGames,
  getLastDigitOfANumber,
  getPastDaysGames,
  sumOfDigits,
} from "../../util";
import deleteIcon from "./images/delete_red.png";
import { updateCollection } from "../../services/firebase";
import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";

const EditFatafatResultScreen = ({ data }) => {
  const navigate = useNavigate();
  const [currentDayResults, setResults] = useState(
    getCurrentDayGames(data.fatafatResults)
  );

  const handleOnChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      const updatedResult = [...currentDayResults];
      updatedResult[index][name] = value;
      updatedResult[index]["winnerValue"] = getLastDigitOfANumber(
        sumOfDigits(parseInt(value))
      ).toString();
      setResults(updatedResult);
    },
    [currentDayResults]
  );

  const onDeleteResult = useCallback(
    (index) => {
      let fatafatCurrentDayResultList = [
        ...getCurrentDayGames(data.fatafatResults),
      ];

      fatafatCurrentDayResultList.splice(index, 1);

      let updatedData = {
        ...data,
        fatafatResults: [
          ...getPastDaysGames(data.fatafatResults),
          ...fatafatCurrentDayResultList,
        ],
      };
      console.log("onDeleteResult data", updatedData);
      updateCollection(
        FIREBASE_COLLECTION_KEY,
        FIREBASE_COLLECTION_DOC_KEY,
        updatedData
      )
        .then((response) => {
          console.info(
            "onDeleteResult firebase updated successfully!",
            response
          );
          window.location.reload();
        })
        .catch((e) => {
          console.error("onDeleteResult firebase updated failed!", e);
          alert("Error", "Can not delete." + e);
        });
    },
    [data]
  );

  const onSaveResult = useCallback(() => {
    // navigate(`${ROUTE_PATHS.EDIT_FATAFAT_RESULT_DETAIL}`);
    console.log("updatedState", currentDayResults);
    let updatedData = {
      ...data,
      fatafatResults: [
        ...getPastDaysGames(data.fatafatResults),
        ...currentDayResults,
      ],
    };
    console.log("onSaveResult data", updatedData);
    updateCollection(
      FIREBASE_COLLECTION_KEY,
      FIREBASE_COLLECTION_DOC_KEY,
      updatedData
    )
      .then((response) => {
        console.info("onSaveResult firebase updated successfully!", response);
        setTimeout(() => {
          navigate(ROUTE_PATHS.HOME);
        }, 100);
      })
      .catch((e) => {
        console.error("onSaveResult firebase updated failed!", e);
        alert("Error", "Can not save." + e);
      });
  }, [currentDayResults, data, navigate]);
  return (
    <>
      <div className="container round_border_card">
        <div className="d-flex align-items-center">
          <h2 className="white-header w-100">Edit/Delete Fatafat Results </h2>
          <div className="save-btn mr-2" onClick={onSaveResult}>
            Save
          </div>
        </div>
        <div className="panel-detail">
          {currentDayResults.map((item, index) => {
            return (
              <div className="d-flex mt-2">
                <input
                  id="startTime"
                  name="startTime"
                  className="mr-2 w-25"
                  required
                  value={item.startTime}
                  onChange={(e) => handleOnChange(e, index)}
                />
                <input
                  type="number"
                  id="resultValue"
                  name="resultValue"
                  className="mr-2 w-25"
                  maxLength={3}
                  minLength={3}
                  required
                  value={item.resultValue}
                  onChange={(e) => handleOnChange(e, index)}
                />

                <div className=" mr-2">
                  <img
                    className="img-btn mr-2"
                    src={deleteIcon}
                    title={`delete fatafatresult`}
                    alt={`delete-fatafatresult`}
                    onClick={() => onDeleteResult(index)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EditFatafatResultScreen;
