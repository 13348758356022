import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants";
import { getCurrentDayGames } from "../../../util";
import editIcon from "../images/edit.png";

const FatafatResultsCard = ({ isAdminLoggedIn, data }) => {
  const navigate = useNavigate();
  const todaysGames = getCurrentDayGames(data);

  const onEditGame = useCallback(() => {
    navigate(`${ROUTE_PATHS.EDIT_FATAFAT_RESULT_DETAIL}`);
  }, [navigate]);
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="matka-results" style={{ paddingBottom: 0 }}>
          <h2>Tata Time Fatafat</h2>
          <>
            <div className="d-flex align-items-center justify-content-center">
              {isAdminLoggedIn && (
                <div className="d-flex justify-content-center mr-3">
                  <img
                    className="img-btn mr-2"
                    src={editIcon}
                    title={`edit game`}
                    alt={`edit-game`}
                    onClick={() => onEditGame()}
                  />
                  <Link
                    to={ROUTE_PATHS.ADD_FATAFAT_RESULT}
                    title="Add Fatafat Result"
                    className="add-fatafat-btn"
                  >
                    +
                  </Link>
                </div>
              )}

              <Link
                to={ROUTE_PATHS.FATAFAT_RESULT_DETAIL}
                title="Fatafat chart"
                className="addGameBtn"
              >
                Chart
              </Link>
            </div>
          </>
        </div>
        {todaysGames.length === 0 ? (
          <div className="shadow_card">
            <h1>No results available!</h1>
          </div>
        ) : (
          <div className="fatafat-card">
            <table>
              <div className="fatafat-card-row">
                <tbody>
                  <tr>
                    <th>Round</th>
                    <th>Result</th>
                  </tr>
                  {todaysGames
                    .filter((_item, index) => index % 2 === 0)
                    .map((item, index) => {
                      return (
                        <tr key={`tibletable-${index}`}>
                          <td>{item.startTime}</td>
                          <td>
                            {item.resultValue} - {item.winnerValue}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tbody>
                  <tr>
                    <th>Round</th>
                    <th>Result</th>
                  </tr>
                  {todaysGames
                    .filter((_item, index) => index % 2 !== 0)
                    .map((item, index) => {
                      return (
                        <tr key={`tibletable-${index}`}>
                          <td>{item.startTime}</td>
                          <td>
                            {item.resultValue} - {item.winnerValue}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </div>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default FatafatResultsCard;
