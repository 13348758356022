import React, { useCallback, useState } from "react";
import { updateCollection } from "../../services/firebase";
import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
} from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAddUpdateGameInitialState,
  getCurrentDayGames,
  getLastDigitOfANumber,
  getPastDaysGames,
  sumOfDigits,
} from "../../util";
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";

const AddFatafatResultScreen = ({ data }) => {
  const navigate = useNavigate();

  const params = useParams();

  const selectedGameIndex = params?.gameIndex ?? null;
  const [{ startTime, resultValue }, setState] = useState(
    getAddUpdateGameInitialState(
      getCurrentDayGames(data?.fatafatResults ?? []),
      selectedGameIndex
    )
  );

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleAddGameSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (startTime !== "" && resultValue !== "") {
        const gameObject = {
          startTime: startTime,
          resultValue: resultValue !== "" ? resultValue : "***",
          winnerValue: getLastDigitOfANumber(
            sumOfDigits(parseInt(resultValue))
          ).toString(),
          createdTime: new Date().toISOString(),
        };
        const pastDaysGameList = getPastDaysGames(data?.fatafatResults ?? []);
        const currentDayGameList = getCurrentDayGames(
          data?.fatafatResults ?? []
        );
        if (selectedGameIndex === null) {
          currentDayGameList.push(gameObject);
        } else {
          currentDayGameList[selectedGameIndex] = gameObject;
        }

        let updatedData = {
          ...data,
          fatafatResults: [
            ...new Set([...pastDaysGameList, ...currentDayGameList]),
          ],
        };
        console.log("handleAddGameSubmit data", updatedData);
        updateCollection(
          FIREBASE_COLLECTION_KEY,
          FIREBASE_COLLECTION_DOC_KEY,
          updatedData
        )
          .then((response) => {
            console.info("firebase updated successfully!", response);
            setTimeout(() => {
              navigate(ROUTE_PATHS.HOME);
            }, 100);
          })
          .catch((e) => {
            console.error("firebase updated failed!", e);
          });
      } else {
        alert("Enter all the details.");
      }
    },
    [data, navigate, resultValue, selectedGameIndex, startTime]
  );

  return (
    <>
      <div className="login">
        <div className="container round_border_card">
          <h2 className="text-center">
            {selectedGameIndex === null
              ? "Add New Fatafat Result"
              : "Update Fatafat Result Detail"}
          </h2>
          <br />
          <form
            name="adminAddFatafatResultGame"
            validate
            onSubmit={handleAddGameSubmit}
            className="loginForm"
          >
            <div className="input-group mt-2">
              <label className="label font-weight-bold">Select Time</label>
              {data.fatafat_result_timings.map((timing) => {
                return (
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="radio"
                          id="startTime"
                          name="startTime"
                          // className="mb-0"
                          required
                          value={timing}
                          checked={startTime === timing}
                          onChange={handleChange}
                        />
                        <span className="font-weight-bold ml-3">{timing}</span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              {/* <input
                type="time"
                id="startTime"
                name="startTime"
                className="form-control"
                placeholder="Enter the game time*"
                required
                onChange={handleChange}
                value={startTime}
              /> */}
              <span className="error-message"></span>
            </div>

            <div className="input-group mt-2">
              <label className="label">Game Result</label>
              <input
                type="number"
                id="resultValue"
                name="resultValue"
                className="form-control"
                placeholder="Enter the game results*"
                maxLength={3}
                minLength={3}
                onChange={handleChange}
                value={resultValue}
              />
              <span className="error-message"></span>
            </div>
            <br />
            <button className="button btn btn-custom btn-lg" type="submit">
              {selectedGameIndex === null ? "Add" : "Update"}
            </button>
            <button
              className="button btn btn-custom btn-lg mt-2 ml-0"
              onClick={() => navigate(ROUTE_PATHS.HOME)}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddFatafatResultScreen;
