import React from "react";
import { getFatafatResultsDetailData } from "../../util";
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";

const FatafatDetailScreen = ({ data }) => {
  const fatataResultTimings = data?.fatafat_result_timings ?? [];
  return (
    <>
      <div className="round_border_card">
        <h2 className="white-header">Tata Time Fatafat Chart </h2>
        <div className="panel-detail">
          <table>
            <tbody>
              <tr>
                <th>Date</th>
                {fatataResultTimings.map((timeVal) => {
                  return <th>{timeVal}</th>;
                })}
              </tr>
              {getFatafatResultsDetailData(data.fatafatResults, fatataResultTimings.length).map((item, index) => {
                return (
                  <tr key={`tibletable-${index}`}>
                    <td>{item.date.split("T")[0]}  <br />  <br /> </td>
                    <>
                      {item.dateWiseData.map((day) => {
                        return (
                          <td>
                            <div class="panel-detail-td">
                              <span>{day.resultValue}</span>
                              <br />
                              <span className="font-weight-bold">{day.winnerValue}</span>
                            </div>
                          </td>
                        );
                      })}
                    </>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FatafatDetailScreen;
