import React from "react";

const TopBanner = ({ isAdminLoggedIn, data, adminUserName }) => {
  return (
    <div
      className="container-fluid"
      // style={{ backgroundColor: "rgba(1,67,154,0.9)" }}
    >
      <div className="container round_border_card mt-5">
        <div className="row">
          <div className="phone-num">
            <h2 className="white-text">
              <b>
                {!isAdminLoggedIn ? data.title : `Welcome ${adminUserName}`}
              </b>
            </h2>
            <h2 className="white-text">
              <b>{data.hashTag}</b>
            </h2>
            <a href={`tel:${data.contactNumber}`}>
              {data.callUs} <span>{data.contactNumber}</span>
            </a>
          </div>
          <hr />
          <p style={{ color: "white" }}>{data.description}</p>
        </div>
      </div>
    </div>
  );
};
export default TopBanner;
