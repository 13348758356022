import React, { useCallback, useState } from "react";
import { updateCollection } from "../../services/firebase";
import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
} from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { getAddUpdateGameInitialState, getCentralMatkaNumber, getCurrentDayGames, getPastDaysGames, onTimeChange, sortListByHHMMTime } from "../../util";

import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";

const AddGameScreen = ({ data }) => {
  const navigate = useNavigate();

  const params = useParams();
  
  const selectedGameIndex = params?.gameIndex ?? null;
  const [
    { gameName, startTime, endTime, lhsValue, rhsValue, createdTime },
    setState,
  ] = useState(getAddUpdateGameInitialState(getCurrentDayGames(data.gameTypes), selectedGameIndex));
  const [selectedGameTimeIndex, setSelectedGameTimeIndex] = useState(parseInt(selectedGameIndex ?? 0) ?? 0)

  const sortedGameTimeTableList = sortListByHHMMTime(data.gamesTimeTable);
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleAddGameSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      console.log(selectedGameTimeIndex)

      if (selectedGameTimeIndex !== null) {
        const selectedGame = sortedGameTimeTableList[selectedGameTimeIndex];
        const gameObject = {
          name: selectedGame.name,
          startTime: selectedGame.openTime,
          endTime: selectedGame.closeTime,
          lhsValue: lhsValue !== '' ? lhsValue : '***',
          rhsValue: rhsValue !== '' ? rhsValue : '***',
          winnerValue: getCentralMatkaNumber(parseInt(lhsValue), parseInt(rhsValue)),
          createdTime: (selectedGameIndex === null || createdTime === '') ? new Date().toISOString() : createdTime,
        };
        const pastDaysGameList = getPastDaysGames(data.gameTypes);
        const currentDayGameList = getCurrentDayGames(data.gameTypes);
        if (selectedGameIndex === null) {
          currentDayGameList.push(gameObject);
        } else {
          currentDayGameList[selectedGameIndex] = gameObject;
        }

        let updatedData = {
          ...data,
          gameTypes: [...new Set([...pastDaysGameList, ...currentDayGameList])],
        };
        console.log("handleAddGameSubmit data", {gameObject, updatedData});
        updateCollection(
          FIREBASE_COLLECTION_KEY,
          FIREBASE_COLLECTION_DOC_KEY,
          updatedData
        )
          .then((response) => {
            console.info("firebase updated successfully!", response);
            navigate(ROUTE_PATHS.HOME);
          })
          .catch((e) => {
            console.error("firebase updated failed!", e);
          });
      } else {
        alert("Enter all the details.");
      }
    },
    [data, endTime, gameName, lhsValue, navigate, rhsValue, selectedGameIndex, startTime, selectedGameTimeIndex]
  );
  return (
    <>
      <div className="login">
        <div className="container round_border_card">
          <h2 className="text-center">{selectedGameIndex === null ? 'Add New Game' : 'Update Game Detail'}</h2>
          <br />
          <form
            name="adminAddGame"
            validate
            onSubmit={handleAddGameSubmit}
            className="loginForm"
          >
            <div className="input-group">
            {sortedGameTimeTableList.map((gameType, index) => {
                return (
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="radio"
                          id="selectedGameTime"
                          name="selectedGameTime"
                          // className="mb-0"
                          required
                          disabled={selectedGameIndex !== null ? selectedGameTimeIndex !== index : false}
                          value={gameType.openTime}
                          checked={selectedGameTimeIndex === index}
                          onChange={(e) => setSelectedGameTimeIndex(index)}
                        />
                        <span className="font-weight-bold ml-3">{`${onTimeChange(gameType.openTime)} - ${onTimeChange(gameType.closeTime)} ${gameType.name}`}</span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              {/* <label className="label">Game Name</label>
              <input
                type="text"
                id="gameName"
                name="gameName"
                className="form-control"
                placeholder="Enter the Game Name*"
                required
                onChange={handleChange}
                value={gameName}
              /> */}
              <span className="error-message"></span>
            </div>
            {/* <div className="input-group mt-2">
              <label className="label">Start Time</label>
              <input
                type="time"
                id="startTime"
                name="startTime"
                className="form-control"
                placeholder="Enter the start time*"
                required
                onChange={handleChange}
                value={startTime}
              />
              <span className="error-message"></span>
            </div> */}
            {/* <div className="input-group mt-2">
              <label className="label">End Time</label>
              <input
                type="time"
                id="endTime"
                name="endTime"
                className="form-control"
                placeholder="Enter the end time*"
                required
                onChange={handleChange}
                value={endTime}
              />
              <span className="error-message"></span>
            </div> */}
            <div className="input-group mt-2">
              <label className="label">First Game Digits</label>
              <input
                type="number"
                id="lhsValue"
                name="lhsValue"
                className="form-control"
                placeholder="Enter the first game digits*"
                maxLength={3}
                minLength={3}
                onChange={handleChange}
                value={lhsValue}
              />
              <span className="error-message"></span>
            </div>
            <div className="input-group mt-2">
              <label className="label">Second Game Digits</label>
              <input
                type="number"
                id="rhsValue"
                name="rhsValue"
                className="form-control"
                placeholder="Enter the second game digits*"
                maxLength={3}
                minLength={3}
                onChange={handleChange}
                value={rhsValue}
              />
              <span className="error-message"></span>
            </div>
            <br />
            <button className="button btn btn-custom btn-lg" type="submit">
            {selectedGameIndex === null ? 'Add' : 'Update'}
            </button>
            <button
              className="button btn btn-custom btn-lg mt-2 ml-0"
              onClick={() => navigate(ROUTE_PATHS.HOME)}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddGameScreen;
