import React, { useCallback, useEffect, useState } from "react";
import WaLogo from "./images/wa.png";
import { ADMIN_LOGIN_KEY, ChartsType, ROUTE_PATHS } from "../../constants";
import {
  getCurrentDayGames,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "../../util";
import {
  FatafatResultCard,
  GamesTimeTableCard,
  JodiPanelChartsCard,
  MatkaGamesRate,
  MatkaResultsCard,
  NavBarHeader,
  NoticeBoardCard,
  PlaystoreAppStoreCard,
  SocialLinksFooterCard,
  TopBanner,
} from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";

const MatkaLandingpage = ({ matkaPlayData, adminLoggedInSuccess = false, superAdminLogin = false }) => {
  const [isAdminLoggedIn, setAdminLoggedIn] = useState(adminLoggedInSuccess);
  const [adminUserName, setAdminUserName] = useState("");
  const [isOpen, setModalStatus] = useState(false);

  useEffect(() => {
    const loggedInData = JSON.parse(getItemFromLocalStorage(ADMIN_LOGIN_KEY));
    if (loggedInData && loggedInData.userName) {
      setAdminUserName(loggedInData.userName);
      setAdminLoggedIn(true);
    } else {
      setAdminLoggedIn(false);
    }
  }, [isAdminLoggedIn, setAdminLoggedIn, adminLoggedInSuccess]);

  const handleLogout = useCallback((e) => {
    e.preventDefault();
    window.location.reload();
    removeItemFromLocalStorage(ADMIN_LOGIN_KEY);
  }, []);

  const onNewGameAdd = useCallback(
    (e) => {
      e.preventDefault();
      setModalStatus(!isOpen);
    },
    [isOpen]
  );

  const onSaveAll = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <>
      {/* <NavBarHeader isAdminLoggedIn={isAdminLoggedIn} handleLogout={handleLogout}/> */}
      {/* navbarmenu */}
      {/* <div className="container-fluid">
        <div className="row gold-menu-area">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="gold-menu">
              <ul>
                {navBarItems.map((item, index) => {
                  return (
                    <Link
                      key={`${item}-${index}`}
                      to={`${ROUTE_PATHS.HTML_CONTENT_SCREEN}/${item.hrefVal}`}
                      title={`${item.title}`}
                    >
                      {item.title}
                    </Link>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <TopBanner
        isAdminLoggedIn={isAdminLoggedIn}
        data={matkaPlayData.topBanner}
        adminUserName={adminUserName}
      />

      <div className="container-fluid">
        <div className="row how-play-area">
          <div className="container">
            <NoticeBoardCard data={matkaPlayData.noticeBoardCard} />
            <MatkaGamesRate data={matkaPlayData.gameRatesCard} />
          </div>
        </div>
      </div>

      <MatkaResultsCard
        data={matkaPlayData}
        isAdminLoggedIn={isAdminLoggedIn}
        onAddClick={onNewGameAdd}
        onSaveClick={onSaveAll}
      />

      <FatafatResultCard
        data={matkaPlayData?.fatafatResults ?? []}
        isAdminLoggedIn={isAdminLoggedIn}
      />

      <div className="container-fluid blue-background">
        <div className="container round_border_card">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="gcon-area">
                <h2>Matka Play Online</h2>
                <p>Call us to play online matka</p>
                <h3>
                  {/* <i className="fa fa-phone" aria-hidden="true"></i>{" "} */}
                  <FontAwesomeIcon icon={faPhone} size="sm" />{" "}
                  {matkaPlayData["contact-us"].mobileNumber}
                </h3>
                <hr />
              </div>
            </div>
            <p className="text-center white-text">
              Get Lucky and and Play the satta matka game with the most minimum
              amount. We give you expert kalyan matka suggestions so that you
              can full fill your dreams with your own luck. Play Matka Online
              Today!
            </p>
          </div>
        </div>
      </div>

      <GamesTimeTableCard data={matkaPlayData} />

      <JodiPanelChartsCard
        title={"Jodi Chart"}
        data={matkaPlayData.gameTypes}
        type={ChartsType.JODI}
      />
      <JodiPanelChartsCard
        title={"Panel Chart"}
        data={matkaPlayData.gameTypes}
        type={ChartsType.PANEL}
      />

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="heading">Online Matka</h3>
              <p>
                Live results on all the online matka play markets, every day on
                Matka Play
              </p>
              <p>
                Here at MatkaPlay we don't like matka, we love it. Our
                consistant aim to match your obsession and passion for the game
                by bringing the best in online matka, from kalyan and other
                major houses. We cover almost all the major matka play markets
                including rajdhani, kalyan, main ratan and navratna.
              </p>
              <p>
                There are other markets that are also avialable along side the
                above mentioned, across dozens of others. All these can be
                accessed through our web application matkaplay, Meaning you can
                easily bid weather you are at home or busy with some other work.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="heading">Matka Play</h3>
              <p>
                The exact orgins of matka re unknown, but historians belive that
                matka originated from india and was played informally amount
                communities and villages. With the origin and birth home of
                matka being right here in india. We brign exponential knowledge
                in online matka playing.
              </p>
              <p>
                With our knowledge in the matka game, you will find every
                feature in our application useful and helpful. Providing
                competitive rates and the best user experience to our users has
                been the main goal all along.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="heading">Why Choose Us</h3>
              <p>
                We gladly would appretiate that there are a lot of matka play
                sites out there, but we strongly trust that MatkaPlay.net stands
                out from the rest.
              </p>
              <p>
                We offer a unique platform and this is our fundamental offering.
                Our platfrom is mobile and user friendly. Hence, navigating
                through our website is as easy as learning numerics. We offer an
                online matka play app what works on both android and ios
                platforms.{" "}
              </p>
              <p>
                Our fantastic website serves various range of matka play markets
                and games that are backed by the best matka game owners. along
                with insider news and special offers we are an all in one matka
                play destination.
              </p>
              <p>
                Are you interested in the thrill of matka games, at matka play
                we offer various online matka play classics such as{" "}
                <a href="./single.html">single</a>,{" "}
                <a href="./jodi.html">jodi</a>,{" "}
                <a href="./single-patti.html">single patti</a> and{" "}
                <a href="double-patti.html">double patti</a>. All these online
                matka games are new to{" "}
                <a href="./index.html">online matka play</a>.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <PlaystoreAppStoreCard /> */}

      <div className="row flot-what">
        <a
          href={`whatsapp://send?text=Hello!&phone=${matkaPlayData["contact-us"].mobileNumber}`}
        >
          <img src={WaLogo} alt="satta matka whatsapp" />
        </a>
        <div
          onClick={() => window.location.reload()}
          href=""
          className="refresh-btn"
        >
          REFRESH{" "}
        </div>
      </div>
      {/* social links */}
      <SocialLinksFooterCard data={matkaPlayData["contact-us"]} />
    </>
  );
};

export default MatkaLandingpage;
