import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
} from "./constants";
import { updateCollection } from "./services/firebase";
import { startOfWeek, addDays, format } from "date-fns";

import MainBazarData from "./data/tatamatka/main_bazaar.json";
import MilanData from "./data/tatamatka/milan.json";
import RajdhaniNightData from "./data/tatamatka/rajdhani_night.json";
import TataRajdhaniData from "./data/tatamatka/tata_rajdhani.json";
import TataTimeBazarData from "./data/tatamatka/tata_time_bazaar.json";
import KalyanData from "./data/tatamatka/kalyan.json";
import BhootnathData from "./data/tatamatka/bhootnath.json";

export interface GameObject {
  lhsValue: string;
  createdTime: string;
  rhsValue: string;
  name: string;
  endTime: string;
  winnerValue: string;
  startTime: string;
}
export const isDev = () =>   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const insertSpaces = (str: string) => {
  if (Array.isArray(str)) {
    str = str.join(" ").replace(/ +/g, "");
  }
  if (typeof str !== "string") {
    return ""; // Return an empty string or handle the error as appropriate for your use case.
  }

  const regex = /^(\w{3})(\w{2})(\w+)/;
  const modifiedString = str.replace(regex, "$1 $2 $3");
  return modifiedString;
};
export const is11AMOrLater = () => {
  // Get the current date and time
  const currentDate = new Date();
  // Set the time to 11:00 AM in IST
  // const targetTime = new Date(currentDate);
  // targetTime.setHours(11, 0, 0);
  // targetTime.setMinutes(
  //   targetTime.getMinutes() + currentDate.getTimezoneOffset()
  // );
  const istOffset = 5.5 * 60 * 60 * 1000;
  const currentIstTime = new Date(currentDate.getTime() + istOffset);

  // Extract hours and minutes from the IST time
  const hours = currentIstTime.getUTCHours();
  // Compare the current time with the target time

  console.log("is11AMOrLater {currentDate, targetTime} ==>.", {
    currentDate,
    // targetTime,
    hours
  });
  // if (currentDate >= targetTime) {
  if (hours >= 11) {
    console.log("Current time is 11 AM or later in IST.");
    return true;
  } else {
    console.log("Current time is before 11 AM in IST.");
    return false;
  }
};
export const onTimeChange = (inputTime) => {
  let timeSplit = inputTime.split(":");
  let hours = 0;
  let minutes = 0;
  let meridian = "AM";
  hours = timeSplit[0];
  minutes = timeSplit[1];
  if (hours > 12) {
    meridian = "PM";
    hours -= 12;
  } else if (hours < 12) {
    meridian = "AM";
    if (hours == 0 || hours == parseInt("00")) {
      hours = 12;
    }
  } else {
    meridian = "PM";
  }
  return `${hours}:${minutes} ${meridian}`;
};
export const getOldDataByName = (gameName: string) => {
  /**
   * Tata Time Bazaar
   * Tata Rajdhani
   * Milan
   * Bhoothnath
   * Rajdhani Night
   * Main Bazaar
   */
  const dataByNameObj = {
    "Main Bazaar": MainBazarData,
    Milan: MilanData,
    "Rajdhani Night": RajdhaniNightData,
    "Tata Rajdhani": TataRajdhaniData,
    "Tata Time Bazaar": TataTimeBazarData,
    Kalyan: KalyanData,
    Bhootnath: BhootnathData,
  };
  if (Object.keys(dataByNameObj).includes(gameName)) {
    return dataByNameObj[gameName];
  }
  return { data: [] };
};

export const validatePhoneNumber = (phoneNumber: string) => {
  let errors = {};
  let isValid = true;
  // if (!input["Mobilenumber"]) {
  //     isValid = false;
  //     errors["Mobilenumber"] = "Please enter your Mobile Number.";
  // }
  if (phoneNumber.length === 0) {
    errors["mobilenumber"] = "";
    return {
      isValid,
      errors,
    };
  }
  if (typeof phoneNumber !== "undefined") {
    const pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    if (!pattern.test(phoneNumber)) {
      isValid = false;
      errors["mobilenumber"] = "Please Enter Number Only";
    } else if (phoneNumber.length < 10) {
      isValid = false;
      errors["mobilenumber"] = "Enter at least 10 digits mobile number.";
    } else if (phoneNumber.length > 10) {
      isValid = false;
      errors["mobilenumber"] = "Mobile number should be maximum of 10 digits.";
    }
  }

  return {
    isValid,
    errors,
  };
};

export const validatePANnumber = (panVal: string) => {
  const regpan = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);

  if (panVal.length === 0) {
    return {
      error: "",
    };
  }
  if (regpan.test(panVal)) {
    return {
      error: "",
    };
  } else {
    // invalid pan card number
    return {
      error: "Please enter valid PAN number.",
    };
  }
};

export const sumOfDigits = (num: number) => {
  let sum = 0;

  while (num) {
    sum += num % 10;
    num = Math.floor(num / 10);
  }
  return sum;
};
export const getLastDigitOfANumber = (num: number) => {
  return Number.isInteger(num) ? num % 10 : num.toString().slice(-1);
};
export const getCentralMatkaNumber = (lhsVal = 0, rhsVal = 0) => {
  const fistDigit =
    !isNaN(lhsVal) && lhsVal > 0
      ? getLastDigitOfANumber(sumOfDigits(lhsVal)).toString()
      : "*";
  const secondDigit =
    !isNaN(rhsVal) && rhsVal > 0
      ? getLastDigitOfANumber(sumOfDigits(rhsVal)).toString()
      : "*";

  return `${fistDigit}${secondDigit}`;
};
export const compareTimes = (obj1: any, obj2: any) => {
  const [hours1, minutes1] = obj1.openTime.split(":").map(Number);
  const [hours2, minutes2] = obj2.openTime.split(":").map(Number);

  if (hours1 !== hours2) {
    return hours1 - hours2;
  } else {
    return minutes1 - minutes2;
  }
};
export const sortListByHHMMTime = (timeArray: any) => {
  return timeArray.sort(compareTimes);
};
//locale storage
export const saveItemInLocalStorage = (key: string, data: string) => {
  localStorage.setItem(key, data);
};

export const getItemFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error("Local storage item not found");
    return undefined;
  }
};

export const removeItemFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error("Local storage item not found for remove");
  }
};

//
export const getWeekDaysInMonth = () => {
  function getWeeksInMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

    const firstMonday =
      firstDayOfMonth.getDate() + ((1 - firstDayOfMonth.getDay() + 7) % 7);
    const lastSunday =
      lastDayOfMonth.getDate() - ((lastDayOfMonth.getDay() + 6) % 7);

    const weeks: any = [];
    let currentDay = new Date(currentYear, currentMonth, firstMonday);

    while (currentDay.getDate() <= lastSunday) {
      const startOfWeek = new Date(currentDay);
      const endOfWeek = new Date(currentDay.setDate(currentDay.getDate() + 6));

      weeks.push({ startOfWeek, endOfWeek });

      currentDay.setDate(currentDay.getDate() + 1);
    }

    return weeks;
  }

  // Usage:
  const weeksInMonth = getWeeksInMonth();
  console.log(weeksInMonth);
};
export const getWeeklyData = (data: any[]) => {
  const sortedData = data.sort(
    (a: any, b: any) =>
      new Date(a.createdTime).valueOf() - new Date(b.createdTime).valueOf()
  );
  let weeklyData: any = [];

  let startOfWeek: Date | null = null;
  let endOfWeek: Date | null = null;
  let currentWeekData = {};

  for (const entry of sortedData) {
    const currentDate = new Date(entry.createdTime);

    if (!startOfWeek) {
      // First entry
      let startOfWeek = new Date(currentDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
      let endOfWeek: Date = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      let currentWeekData: any = {};
      currentWeekData[currentDate.getDay()] = [entry];
    } else if (
      (currentDate as Date) >= (startOfWeek as Date) &&
      endOfWeek !== null &&
      currentDate <= (endOfWeek as Date)
    ) {
      // Date is within the current week
      const dayOfWeek = currentDate.getDay();
      if (!currentWeekData[dayOfWeek]) {
        currentWeekData[dayOfWeek] = [entry];
      } else {
        currentWeekData[dayOfWeek].push(entry);
      }
    } else {
      // Date is in a new week
      weeklyData.push(currentWeekData);
      if (startOfWeek !== null && endOfWeek !== null) {
        (startOfWeek as Date).setDate((startOfWeek as Date).getDate() + 7);
        (endOfWeek as Date).setDate((endOfWeek as Date).getDate() + 7);
      }
      currentWeekData = {};
      currentWeekData[currentDate.getDay()] = [entry];
    }
  }

  // Add the last week's data
  weeklyData.push(currentWeekData);
  console.log("weeklydata weeklyData", weeklyData);
  return weeklyData;

  // const data2 = [
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-12",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-13",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-14",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-15",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-16",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-17",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-18",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-19",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-20",
  //   },
  //   {
  //     name: "Kalyan",
  //     startTime: "",
  //     endTime: "",
  //     lhsValue: "405",
  //     rhsValue: "605",
  //     winnerValue: "01",
  //     createdTime: "2023-06-21",
  //   },
  // ];

  // const weeklyDataByWeekDays = groupDataByWeekDays(data);
  // console.log(weeklyDataByWeekDays);
};

export const getDataByGameName = (data: any[], filterKey: string) => {
  return data.filter((item) => item.name === filterKey);
};

export const getWeekwisedata = (data: GameObject[]) => {
  const segregatedData: { [week: string]: GameObject[] } = {};

  data.forEach((obj) => {
    const date = new Date(obj.createdTime);
    const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Set week start as Monday
    const weekEnd = addDays(weekStart, 6); // Calculate week end as Sunday
    const weekStartString = format(weekStart, "dd/MM/yy");
    const weekEndString = format(weekEnd, "dd/MM/yy");

    const weekRange = `${weekStartString} to ${weekEndString}`;

    if (segregatedData[weekRange]) {
      segregatedData[weekRange].push(obj);
    } else {
      segregatedData[weekRange] = [obj];
    }
  });

  return segregatedData;
};
export const getPanelJodiChartDetailData = (data: any[], filterKey: string) => {
  const dataByGameName = getPanelJodiChartData(data)[filterKey];
  // const filteredData = data.filter((item) => item.name === filterKey);
  const sortedData = dataByGameName.sort(
    (a: any, b: any) =>
      new Date(a.createdTime).valueOf() - new Date(b.createdTime).valueOf()
  );
  const weekwisedata = getWeekwisedata(sortedData);
  return Object.keys(weekwisedata).map((item) => {
    // check for empty days
    let dayList = weekwisedata[item];
    const desiredLength = 7;
    const currentLength = dayList.length;

    if (currentLength < desiredLength) {
      const numberOfItemsToAdd = desiredLength - currentLength;
      for (let i = 0; i < numberOfItemsToAdd; i++) {
        dayList.push({
          rhsValue: "***",
          lhsValue: "***",
          name: weekwisedata[item][0].name,
          createdTime: weekwisedata[item][0].createdTime,
          startTime: weekwisedata[item][0].startTime,
          endTime: weekwisedata[item][0].endTime,
          winnerValue: "**",
        });
      }
    } else if (currentLength > desiredLength) {
      dayList.splice(desiredLength);
    }
    return {
      weekIntervalTime: item,
      dayWiseData: dayList, //weekwisedata[item],
    };
  });
};

export const getFatafatResultsDetailData = (data: any[], desiredLength = 8) => {
  const sortedData = data.sort(
    (a: any, b: any) =>
      new Date(a.createdTime).valueOf() - new Date(b.createdTime).valueOf()
  );

  const segregatedData = sortedData.reduce((result, item) => {
    const { createdTime } = item;

    if (!result[createdTime.split("T")[0]]) {
      result[createdTime.split("T")[0]] = [];
    }

    result[createdTime.split("T")[0]].push(item);

    return result;
  }, {});

  return Object.keys(segregatedData).map((item) => {
    // check for empty days
    let dayList = segregatedData[item];
    const currentLength = dayList.length;

    if (currentLength < desiredLength) {
      const numberOfItemsToAdd = desiredLength - currentLength;
      for (let i = 0; i < numberOfItemsToAdd; i++) {
        dayList.push({
          resultValue: "***",
          createdTime: segregatedData[item][0].createdTime,
          startTime: segregatedData[item][0].startTime,
          winnerValue: "**",
        });
      }
    } else if (currentLength > desiredLength) {
      dayList.splice(desiredLength);
    }
    return {
      date: item,
      dateWiseData: segregatedData[item],
    };
  });
};

export const getAddUpdateGameInitialState = (data: any[], index = null) => {
  const initialState = {
    gameName: "",
    startTime: "",
    endTime: "",
    lhsValue: "",
    rhsValue: "",
    winnerValue: "",
    createdTime: "",
  };

  try {
    if (index !== null && index !== undefined) {
      const foundObj = data[parseInt(index)];

      return {
        ...initialState,
        gameName: foundObj.name,
        startTime: foundObj.startTime,
        endTime: foundObj.endTime,
        lhsValue: foundObj.lhsValue,
        rhsValue: foundObj.rhsValue,
        winnerValue: foundObj.winnerValue,
        createdTime: foundObj.createdTime,
      };
    }
  } catch (e) {
    return initialState;
  }
  return initialState;
};

export const getCurrentDayGames = (data: any[]) => {
  // new Date().toLocaleDateString()
  /**
   * Tata Time Bazaar
   * Tata Rajdhani
   * Milan
   * Bhoothnath
   * Rajdhani Night
   * Main Bazaar
   */
  const currentdayGames = data.filter((item) => {
    const todayDate = new Date();
    const createdDateTime = new Date(item.createdTime);
    if (
      createdDateTime.getDate() === todayDate.getDate() &&
      createdDateTime.getMonth() === todayDate.getMonth() &&
      createdDateTime.getFullYear() === todayDate.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
    // new Date(item.createdTime).getDate() === new Date().getDate()
  });
  if (currentdayGames.length > 0) {
    return currentdayGames;
  } else if (!is11AMOrLater()) {
    return data.filter((item) => {
      const todayDate = new Date();
      const createdDateTime = new Date(item.createdTime);
      if (
        createdDateTime.getDate() === todayDate.getDate() - 1 &&
        createdDateTime.getMonth() === todayDate.getMonth() &&
        createdDateTime.getFullYear() === todayDate.getFullYear()
      ) {
        return true;
      } else {
        return false;
      }
      // new Date(item.createdTime).getDate() === new Date().getDate()
    });
  } else {
    return [];
  }
};
export const getPastDaysGames = (data: any[]) => {
  if (!is11AMOrLater()) {
    return data.filter(
      (item) => new Date(item.createdTime).getDate() !== new Date().getDate() - 1
    );
  }
  return data.filter(
    (item) => new Date(item.createdTime).getDate() !== new Date().getDate()
  );
  // if (is11AMOrLater()) {
  //   return data.filter((item) => {
  //     const todayDate = new Date();
  //     const createdDateTime = new Date(item.createdTime);
  //     if (
  //       createdDateTime.getDate() !== todayDate.getDate() &&
  //       createdDateTime.getMonth() !== todayDate.getMonth() &&
  //       createdDateTime.getFullYear() !== todayDate.getFullYear()
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // } else {
  //   return data.filter((item) => {
  //     const todayDate = new Date();
  //     const createdDateTime = new Date(item.createdTime);
  //     const isNotTodays =
  //       createdDateTime.getDate() !== todayDate.getDate() &&
  //       createdDateTime.getMonth() !== todayDate.getMonth() &&
  //       createdDateTime.getFullYear() !== todayDate.getFullYear();
  //     const isNotYesterdays =
  //       createdDateTime.getDate() !== todayDate.getDate() - 1 &&
  //       createdDateTime.getMonth() !== todayDate.getMonth() &&
  //       createdDateTime.getFullYear() !== todayDate.getFullYear();
  //     if (isNotTodays || isNotYesterdays) {
  //       return true;
  //     }
  //     return false;
  //   });
  // }
};

export const getYesrdaysDaysGames = (data: any[]) => {
  return data.filter(
    (item) => new Date(item.createdTime).getDate() === new Date().getDate() - 1
  );
};
export const createGamesForCurrentDay = (data: any, callback) => {
  const pastDaysGameList: any[] = getPastDaysGames(data.gameTypes);

  const sortedGameTimeTableList = sortListByHHMMTime(data.gamesTimeTable);
  const todaysGames = sortedGameTimeTableList.map((item) => {
    return {
      name: item.name,
      startTime: item.openTime,
      endTime: item.closeTime,
      lhsValue: "***",
      rhsValue: "***",
      winnerValue: "**",
      createdTime: new Date().toISOString(),
    };
  });
  let updatedData = {
    ...data,
    gameTypes: [...new Set([...pastDaysGameList, ...todaysGames])],
  };
  console.log("createGamesForCurrentDay", { updatedData });
  updateCollection(
    FIREBASE_COLLECTION_KEY,
    FIREBASE_COLLECTION_DOC_KEY,
    updatedData
  )
    .then((response) => {
      console.info(
        "firebase updated successfully for createGamesForCurrentDay!",
        response
      );
      callback(true);
    })
    .catch((e) => {
      console.error("firebase updated failed createGamesForCurrentDay!", e);
    });
};

export const getPanelJodiChartData = (data: any[]) => {
  const segregatedData = {};

  data.forEach((obj) => {
    const gameName = obj.name;

    if (segregatedData[gameName]) {
      segregatedData[gameName].push(obj);
    } else {
      segregatedData[gameName] = [obj];
    }
  });
  return segregatedData;
};
