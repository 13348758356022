// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
// import { isDev } from "./util";
// import { getAnalytics } from "firebase/analytics";

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// "site": "tatakamatkaplaydev",
// firebase deploy --only hosting:tatakamatkaplaydev
export const firebaseConfigDevApp = {
  basename: "",
  defaultPath: "",
  theme: "light",
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: "en",
  rtlLayout: false,
  firebase: {
    apiKey: "AIzaSyD2VWTT4_-2mh6d4MuQ8t1geX_pEwrs6DE",
    authDomain: "tata-matkaplay.firebaseapp.com",
    databaseURL: "https://tata-matkaplay-default-rtdb.firebaseio.com",
    projectId: "tata-matkaplay",
    storageBucket: "tata-matkaplay.appspot.com",
    messagingSenderId: "633421464499",
    appId: "1:633421464499:web:3fb3aa80db99f364a301c6",
    measurementId: "G-3G9D284VN9",
  },
};
export const appConfigProd = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "",
  theme: "light",
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: "en",
  rtlLayout: false,
  firebase: {
    apiKey: "AIzaSyD2VWTT4_-2mh6d4MuQ8t1geX_pEwrs6DE",
    authDomain: "tata-matkaplay.firebaseapp.com",
    projectId: "tata-matkaplay",
    storageBucket: "tata-matkaplay.appspot.com",
    messagingSenderId: "633421464499",
    appId: "1:633421464499:web:9adaf6b42ce9623ca301c6",
    measurementId: "G-7KKBLE04VR",
  },
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(appConfigProd.firebase);
// const analytics = getAnalytics(app);
