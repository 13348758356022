import React, { useCallback, useState } from "react";
// import Logo2 from './images/m-logo2.png'
// import GooglePlayLogo from './images/down-app.webp'
// import WaLogo from './images/wa.png'
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";
// import Script from "./js/login";
import { saveItemInLocalStorage } from "../../util";
import { ADMIN_LOGIN_KEY, SUPER_ADMIN_LOGIN_KEY } from "../../constants";

const initialState = {
  userName: "",
  password: "",
};
const AdminPage = ({ data, onAdminLoginSuccess }) => {
  const [{ userName, password }, setState] = useState(initialState);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleLoginSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // const findItem = data.find(
      //   (userObj) =>
      //     userObj.userName === userName && userObj.password === password
      // );
      if (data[0].userName === userName && data[0].password === password) {
        saveItemInLocalStorage(
          ADMIN_LOGIN_KEY,
          JSON.stringify({ userName, password })
        );
        onAdminLoginSuccess(false);
      } 
      // else if (data[1].userName === userName && data[1].password === password) {
      //   saveItemInLocalStorage(
      //     SUPER_ADMIN_LOGIN_KEY,
      //     JSON.stringify({ userName, password })
      //   );
      //   onAdminLoginSuccess(true);
      // } 
      else {
        alert("Not a valid credential.");
      }

      // if (findItem) {
      //   saveItemInLocalStorage(
      //     ADMIN_LOGIN_KEY,
      //     JSON.stringify({ userName, password })
      //   );
      //   onAdminLoginSuccess();
      // } else {
      //   alert("Not a valid credential.");
      // }
    },
    [data, password, userName, onAdminLoginSuccess]
  );

  return (
    <div className="login">
      <div className="container round_border_card">
        <h2 className="text-center">Login</h2>
        <br />
        {/* <form action="./admindashboard.html" className="loginForm"> */}
        <form
          name="adminLogin"
          validate
          onSubmit={handleLoginSubmit}
          className="loginForm"
        >
          <div className="input-group">
            <label className="label">
              User Name
            </label>
            <input
              type="text"
              id="userName"
              name="userName"
              className="form-control"
              placeholder="Enter the user name*"
              required
              onChange={handleChange}
              // className="input"
            />
            <span className="error-message"></span>
          </div>
          <br />
          <div className="input-group">
            <label className="label">
              Password
            </label>
            <input
              type="password"
              id="password"
              // className="input"
              name="password"
              className="form-control"
              placeholder="Enter the password*"
              required
              onChange={handleChange}
            />
            <span className="error-message"></span>
          </div>
          <br />
          <button className="button btn btn-custom btn-lg" type="submit">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};
export default AdminPage;
