import { useParams } from "react-router-dom";
import { HTML_CONTENT_DICTIONARY } from "./constants";
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";

const HTMLContentScreen = ({ data }) => {
  const params = useParams();
  const htmlKeyName = params.hrefVal;
  const rawHTML = HTML_CONTENT_DICTIONARY[htmlKeyName ?? "single.html"];

  return (
    <div style={container}>
      <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
    </div>
  );
};

export default HTMLContentScreen;

const container = {
  width: 500,
  margin: "50px auto",
};
